import React from "react"
import Seo from '../components/seo'
import Layout from "../components/layout"
import { FormattedMessage } from "react-intl"
import '../style/mogha.scoped.scss'
import Icon from '../components/iconfontCpt'
import { Link } from "gatsby"

const MogHA = ({ pageContext }) => {
  const pageLang = pageContext.lang
  const pagePrefix = pageLang === 'en' ? '/en' : ''
  
  return (
  <Layout pageLang={ pageLang } showHeader={true} showFooter={true} useTransparentTheme={true} customClass="use-translucent-theme">
    <Seo title="MogDB: MogHA"/>
    <div className="mogha-container">
      <div className="mogha-banner">
        <div className="product-banner-box">
          <h1 className="product-banner-title"><FormattedMessage id="mogha.pageTitle" /><i className="mogha"></i></h1>
          <div className="product-banner-desc">
            <p><FormattedMessage id="mogha.pageIntroduce" /></p>
            <p><FormattedMessage id="mogha.pageIntroduce1" /></p>
          </div>
          <div className="btn-group">
            <Link to={`${pagePrefix}/downloads/mogha/all/`} className="btn"><FormattedMessage id="index.download" /></Link>
            <a href={`https://docs.mogdb.io/${pageLang}/mogha/`} target="_blank" className="btn"><FormattedMessage id="index.docs" /></a>
          </div>
        </div>
      </div>
      <div className="introduce-box">
        <div className="tip-box">
          <div className="deploy">
            <div className="introduce-icon">
              <Icon type="icon-deploy" size={70}/>
            </div>
            <div className="feature-title"><FormattedMessage id="mogha.simpleDeployment" /></div>
            <div className="feature-des"><FormattedMessage id="mogha.simpleDeploymentDes" /></div>
          </div>
          <div className="example">
            <div className="introduce-icon">
              <Icon type="icon-decoupling" size={70}/>
            </div>
            <div className="feature-title"><FormattedMessage id="mogha.decoupledFromInstances" /></div>
            <div className="feature-des"><FormattedMessage id="mogha.decoupledFromInstancesDes" /></div>
          </div>
          <div className="dual-mode">
            <div className="introduce-icon">
              <Icon type="icon-double" size={70}/>
            </div>
            <div className="feature-title"><FormattedMessage id="mogha.dualModes" /></div>
            <div className="feature-des"><FormattedMessage id="mogha.dualModesDes" /></div>
          </div>
        </div>
        <div className="introduce">
          <div className="title"><FormattedMessage id="mogha.needMogha" /></div>
          <div className="content">
            <FormattedMessage id="mogha.needMoghaDes" />
          </div>
        </div>
        <div className="functional-characteristics">
          <div className="product-theme-title">
            <FormattedMessage id="mogha.functionsAndCharacteristics" />
          </div>
          <div className="content">
            <div className="failover">
              <div className="features-icon features-icon-1"></div>
              <div className="right">
                <div className="feature-title"><FormattedMessage id="mogha.failoverTitle" /></div>
                <div className="feature-des">
                  <div><FormattedMessage id="mogha.failover1" /></div>
                  <div><FormattedMessage id="mogha.failover2" /></div>
                  <div><FormattedMessage id="mogha.failover3" /></div>
                </div>
              </div>
            </div>
            <div className="instance-monitor">
              <div className="features-icon features-icon-2"></div>
              <div className="right">
                <div className="feature-title"><FormattedMessage id="mogha.instanceMonitoringTitle" /></div>
                <div className="feature-des">
                  <div><FormattedMessage id="mogha.instanceMonitoring1" /></div>
                  <div><FormattedMessage id="mogha.instanceMonitoring2" /></div>
                  <div><FormattedMessage id="mogha.instanceMonitoring3" /></div>
                </div>
              </div>
            </div>
            <div className="double-split">
              <div className="features-icon features-icon-3"></div>
              <div className="right">
                <div className="feature-title"><FormattedMessage id="mogha.splitBrainTitle" /></div>
                <div className="feature-des">
                  <div><FormattedMessage id="mogha.splitBrain1" /></div>
                  <div><FormattedMessage id="mogha.splitBrain2" /></div>
                  <div><FormattedMessage id="mogha.splitBrain3" /></div>
                </div>
              </div>
            </div>
            <div className="virtual-ip">
              <div className="features-icon features-icon-4"></div>
              <div className="right">
                <div className="feature-title"><FormattedMessage id="mogha.virtualIPTitle" /></div>
                <div className="feature-des">
                  <div><FormattedMessage id="mogha.virtualIP1" /></div>
                  <div><FormattedMessage id="mogha.virtualIP2" /></div>
                  <div><FormattedMessage id="mogha.virtualIP3" /></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="experience">
        <div>
          <div className="e-title"><FormattedMessage id="index.experience" /></div>
          <div className="e-content"><FormattedMessage id="mogha.experienceContent" /></div>
          <div className="btn-group">
            <a href={`https://docs.mogdb.io/${pageLang}/mogha/latest/release-notes`} target="_blank" className="btn"><FormattedMessage id="index.useNow" /></a>
            <Link to={`${pagePrefix}/contact/`} className="btn download"><FormattedMessage id="index.contactUs" /></Link>
          </div>
        </div>
      </div>
    </div>
  </Layout>
  )
}
export default MogHA